<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">
      {{ type == "create" ? "AGREGAR" : "EDITAR" }} PRESENTACIONES
    </h1>
    <b-form ref="formAdd" @reset="onReset">
      <div class="row p-3">
        <!-- nombre -->
        <b-form-group
          id="nombre"
          label="Nombre*"
          label-for="nombre"
          class="col-md-6"
        >
          <b-form-input
            id="nombre"
            v-model.trim="form.nombre"
            type="text"
            placeholder="nombre de la presentación"
            ref="nombre"
            :state="nombreState"
            required
          ></b-form-input>
        </b-form-group>
        <!-- descripcion -->
        <b-form-group
          id="descripcion"
          label="Descripción*:"
          label-for="descripcion"
          class="col-md-6"
        >
          <b-form-input
            id="descripcion"
            v-model.trim="form.descripcion"
            type="text"
            placeholder="que ofrece la presentación"
            ref="descripcion"
            :state="descripcionState"
            required
            :max="10"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button variant="primary" @click="handleSubmit">Aceptar</b-button>
      <b-button
        variant="danger"
        class="ml-3"
        @click="$router.push({ name: 'PresentacionesList' })"
        >Cancelar</b-button
      >
    </b-form>
  </div>
</template>

<script>
import PresentacionesService from "@/services/presentaciones.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "PresentacionesProcessAdmin",
  data() {
    return {
      presentacionesService: null,
      form:{
        nombre:null,
        descripcion:null
      },      
      nombreState: null,
      descripcionState: null,
      type: "create",
    };
  },
  created() {
    this.presentacionesService = new PresentacionesService();
    this.$route.params.id ? (this.type = "edit") : (this.type = "create");
  },
  mounted() {
    if (this.type == "edit") {
      this.recoveryDataRegister();
    }
  },
  methods: {
    handleSubmit() {      
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false
      }
      if (this.type === "create") {
        this.createRegister();
      } else {
        this.editRegister();
      }
    },
    recoveryDataRegister() {
      this.presentacionesService
        .getById(this.$route.params.id)
        .then((result) => {
          if(result.data.data){
            this.form = { ...result.data.data };
          }else{
            this.$router.push({name:'PresentacionesList'})
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createRegister() {
      this.inProcess = true;
      this.presentacionesService
        .createPresentacion(this.form)
        .then((result) => {          
          if (result.data.status == 200) {
            this.form = {              
              nombres: null,
              descripcion: null
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro creado satisfactoriamente",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al guardar el registro",
          });
          this.inProcess = false;
        });
    },
    editRegister() {
      this.inProcess = true;
      this.presentacionesService
        .editPresentacion(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {              
              nombres: null,
              descripcion: null
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro editado satisfactoriamente",
            });
            this.$router.push({ name: "PresentacionesList" });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al editar el registro",
          });
          this.inProcess = false;
        });
    },
    onReset() {
      this.form = {        
        nombres: null,
        descripcion: null
      };
      this.$nextTick(() => {
        this.$refs.formAdd.reset();
      });
    },
    validation() {
      /* nombre */
      const validNombre = this.$refs.nombre.checkValidity();
      this.nombreState = validNombre;
      /* descripcion */
      const validDescripcion = this.$refs.descripcion.checkValidity();
      this.descripcionState = validDescripcion;
      return (
        validNombre &&
        validDescripcion
      );
    },
  },
  watch: {
    $route(to) {
      to.params.id ? (this.type = "edit") : (this.type = "create");
      if (this.type == "edit") {
      this.recoveryDataRegister();
    }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}
@media (max-width:1400px) {
 .container-cental {  
  padding: 0px 10px;
} 
}
</style>
